import React from "react";
import { createTheme, styled } from "@material-ui/core/styles";
import {
  Badge,
  Box,
  Typography,
  IconButton,
  Tooltip,
  Link,
  Avatar,
  Grid,
  FormLabel,
  FormControl,
  MenuItem,
  Select,
  CircularProgress,
  Switch,
  FormControlLabel,
  Button
} from "@material-ui/core";
import { GridColumns, GridRenderCellParams } from "@mui/x-data-grid";
import moment from "moment";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import Visibility from "@material-ui/icons/Visibility";
import { UserUnverifiedSVG } from "../../blocks/AdminConsole/src/assets";

export const themeUser = createTheme({
  palette: {
    primary: {
      main: "#000",
      contrastText: "#fff",
    },
    secondary: {
      main: "#DF5200",
      contrastText: "#fff",
    },
  },
  typography: {
    h4: {
      fontWeight: 600,
    },
    subtitle1: {
      fontWeight: 500,
      fontSize: "24px",
      lineHeight: "36px",
    },
    subtitle2: {
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "18px",
      lineHeight: "28px",
      textTransform: "uppercase",
      color: "#1C2324",
    },
    fontFamily: "IBM Plex Sans, Roboto, Helvetica, Arial, sans-serif",
  },
});

export const themeAdmin = createTheme({
  palette: {
    primary: {
      main: "#000",
      contrastText: "#fff",
    },
    secondary: {
      main: "#DF5200",
      contrastText: "#fff",
    },
  },
  spacing: 4,
  typography: {
    h4: {
      fontWeight: 600,
    },
    subtitle1: {
      fontWeight: 500,
      fontSize: "24px",
      lineHeight: "36px",
    },
    subtitle2: {
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "18px",
      lineHeight: "28px",
      textTransform: "uppercase",
      color: "#1C2324",
    },
    fontFamily: "IBM Plex Sans, Roboto, Helvetica, Arial, sans-serif",
  },
});
export const themeFE = createTheme({
  spacing: 4,
  typography: {
    h4: {
      fontWeight: 600,
    },
    subtitle1: {
      fontWeight: 500,
      fontSize: "24px",
      lineHeight: "36px",
    },
    subtitle2: {
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "18px",
      lineHeight: "28px",
      textTransform: "uppercase",
      color: "#1C2324",
    },
    fontFamily: "IBM Plex Sans",
  },
  palette: {
    primary: {
      main: "#000",
      contrastText: "#fff",
    },
    secondary: {
      main: "#DF5200",
      contrastText: "#fff",
    },
  },
});

export const CameraStyledBadge = styled(Badge)(() => ({
  "& .MuiBadge-badge": {
    right: "10%",
    top: "15%",
  },
}));

export const StyledBadge = styled(Badge)(() => ({
  "& .MuiBadge-badge": {
    right: "18%",
    top: "82%",
    width: "1.5rem",
    height: "1.5rem",
    padding: ".8rem",
    backgroundColor: "#F0EBE0",
    borderRadius: "50%",
    color: "#EB8F06",
  },
}));
export const UserProfileStyledBadge = styled(Badge)(() => ({
  "& .MuiBadge-badge": {
    right: "21px",
    top: "81%",
    width: "1.5rem",
    height: "1.35rem",
    padding: ".8rem",
    backgroundColor: "#F0EBE0",
    borderRadius: "50%",
    color: "#EB8F06",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export const StyledBadgeViewAdmin = styled(Badge)(() => ({
  "& .MuiBadge-badge": {
    right: "21px",
    top: "81%",
    width: "1.5rem",
    height: "1.35rem",
    padding: ".8rem",
    backgroundColor: "#F0EBE0",
    borderRadius: "50%",
    color: "#EB8F06",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
}));

export interface DropDownType {
  id: number
  name: string
  name_english: string
  name_hindi: string
  name_gujrati: string
  active: boolean
  created_at: string
  updated_at: string
}
export const unVerifiedBadge = (unverified: boolean) => {
  return (
    <>
      {unverified ?
        <Tooltip title={"Unverified"}>
          <Typography style={{ display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }}>
            <img src={UserUnverifiedSVG} alt="-" style={{height:"24px",width:"24px"}}/>
          </Typography>
        </Tooltip>
        : <></>
      }
    </>
  )
}
export const farmerColumns = (
  handleFarmerEditClick: (data: any) => void,
  handleFarmerDelete: (data: any) => void,
  handleUpdateUserStatus? :(data: any,status:boolean) => void,
): GridColumns => [
    {
      field: "name",
      headerName: "Name",
      minWidth: 140,
      headerAlign: "center",
      sortable: false,
      flex: 1,
      headerClassName: "customColHeader",
      renderCell: (params: GridRenderCellParams) => {

        let firstName = params.row.attributes?.first_name || ""
        let lastName = params.row.attributes?.last_name || ""
        let fullname = firstName + " " + lastName
        let isUserUnverified = !params.row.attributes?.verified 
        return (
          <>
            <Box style={{ display: "flex", justifyContent: "center", alignItems:"center" }}>
              <Tooltip title={fullname}>
                <Typography className="customRowFontNameFarmerNew" noWrap>
                  {fullname}
                </Typography>
              </Tooltip>
              {isUserUnverified ?
                <Box style={{ marginLeft: "4px" }}>
                  <Tooltip title={"Unverified"}>
                    <Typography style={{ display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }}>
                      <img src={UserUnverifiedSVG} alt="-" />
                    </Typography>
                  </Tooltip>
                </Box> : <></>}
            </Box>
          </>
        );
      },
    },
    {
      field: "location",
      sortable: false,
      minWidth: 150,
      headerName: "Location",
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "customColHeader",
      renderCell: (params: GridRenderCellParams) => {
        let VillageName = params.row.attributes?.village_id?.length ? params.row.attributes?.village_id[0].name : ""
        let districtName = params.row.attributes?.district_id?.length ? ", " + params.row.attributes?.district_id[0].name : ""
        let locationNames = VillageName + districtName
        return (
          <Tooltip title={locationNames}>
            <Typography className="customRowFont" noWrap>
              {locationNames}
            </Typography>
          </Tooltip>
        )
      },
    },
    {
      field: "assignedFieldExecutive",
      sortable: false,
      headerName: "Assigned Field Executive",
      minWidth: 160,
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "customColHeaderUpdated",
      renderCell: (params: GridRenderCellParams) => {

        let firstName = params.row.attributes?.assigned_field_executive?.first_name || ""
        let lastName = params.row.attributes?.assigned_field_executive?.last_name || ""
        let FEfullName = firstName + " " + lastName

        let displayFullName = params.row.attributes?.assigned_field_executive ? FEfullName : ""
        return (
          <Tooltip title={displayFullName}>
            <Typography className="customRowFont" noWrap>
              {params.row.attributes?.assigned_field_executive ? FEfullName : "-"}
            </Typography>
          </Tooltip>
        )
      },
    },
    {
      field: "sowingDate",
      sortable: false,
      headerName: "Sowing Date",
      minWidth: 90,
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "customColHeaderUpdated",
      renderCell: (params: GridRenderCellParams) => {
        let tempDate = moment(params.row.attributes?.sowing_date, "YYYY-MM-DD");
        let SowingDate = params.row.attributes?.sowing_date ? tempDate.format("DD/MM/YY") : ""
        return (
          <Tooltip title={SowingDate}>
            <Typography className="customRowFont" noWrap>
              {params.row.attributes?.sowing_date ? tempDate.format("DD/MM/YY") : "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: "NoOfFarmDiaryEntries",
      sortable: false,
      headerName: "No. of farm diary entries",
      minWidth: 110,
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "customColHeaderUpdated",
      renderCell: (params: GridRenderCellParams) => {
        const noOfFarmDiary = params.row.attributes?.number_of_farm_dairy_entries ? params.row.attributes?.number_of_farm_dairy_entries : "0"
        return (
          <Tooltip title={noOfFarmDiary}>
            <Typography className="customRowFont">
              {noOfFarmDiary}
            </Typography>
          </Tooltip>
        )
      },
    },
    {
      field: "NoOfSurveysFilled",
      sortable: false,
      headerName: "No. of surveys filled",
      type: "number",
      minWidth: 95,
      headerAlign: "center",
      headerClassName: "customColHeaderUpdated",
      align: "center",
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        const noOfSurvey = params.row.attributes?.number_of_survey_fields ? params.row.attributes?.number_of_survey_fields : "0"
        return (
          <Tooltip title={noOfSurvey}>
            <Typography className="customRowFont">
              {noOfSurvey}
            </Typography>
          </Tooltip>
        )
      },
    },
    {
      field: "status",
      sortable: false,
      headerName: "Status",
      // type: "number",
      minWidth: 145,
      headerAlign: "center",
      headerClassName: "customColHeader",
      flex: 1,
      align: "center",
      renderCell: (params: GridRenderCellParams) => {
        
        let userStatus = params.row.attributes?.active ? true : false
        let userStatusText = userStatus ? "Active" : "Inactive"
        let textOpacity = userStatus ? 1 : 0.64
        return (
          <>
            <Box>
              <FormControlLabel
                control={
                  <Switch checked={userStatus}
                    // disabled
                    disableRipple
                    disableFocusRipple
                    disableTouchRipple
                    className={"user-status-custom-switch"}
                    onChange={(event) => {
                      handleUpdateUserStatus && handleUpdateUserStatus(params.row, event.target.checked)
                    }}
                  />
                }
                label={
                  <Typography className="customRowFont" style={{ opacity: textOpacity }}>
                    {userStatusText}
                  </Typography>
                }
                labelPlacement="end" // Positions the label to the right of the switch
              />
            </Box>
          </>
        )
      },
    },
    {
      field: " ",
      sortable: false,
      headerName: "",
      headerClassName: "customColHeader",
      headerAlign: "center",
      flex: 1,
      minWidth: 130,
      align: "right",
      renderCell: (params: GridRenderCellParams) => (
        <Box style={{ display: "flex", justifyContent: "center", gap: "18px", marginRight: "12px",alignItems:"center" }}>
          <Tooltip title="Preview">
            <Link href={`/view-farmer-profile/${params.row.id}`} >
              <IconButton
                aria-label="preview"
                disableRipple
                style={{ padding: "0px" }}
                disabled
              >
                <Visibility
                  fontSize="small"
                  style={{
                    cursor: "pointer",
                    color: "#006A77",
                  }}
                />
              </IconButton>
            </Link>

          </Tooltip>
          <Tooltip title="Edit">
            <IconButton
              aria-label="edit"
              disableRipple
              style={{ padding: "0px" }}
              onClick={() => {
                handleFarmerEditClick(params.row);
              }}
            >
              <EditIcon
                fontSize="small"
                style={{
                  cursor: "pointer",
                  color: "#EB8F06",
                }}
              />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton
              aria-label="delete"
              disableRipple
              style={{ padding: "0px" }}
              data-testid={`tableDeleteIconBtn-${params.row.id}`}
              onClick={() => {
                handleFarmerDelete(params.row);
              }}
            >
              <DeleteIcon
                fontSize="small"
                style={{
                  cursor: "pointer",
                  color: "#B3261E",
                }}
              />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

export const fieldExecutiveColumns = (
  handleFeEditClick: (data: any) => void,
  handleFEDelete: (data: any) => void
): GridColumns => [
    {
      field: "name",
      sortable: false,
      headerName: "Name",
      minWidth: 160,
      align: "left",
      headerAlign: "center",
      flex: 1,
      headerClassName: "customColHeader",
      renderCell: (params: GridRenderCellParams) => {
        const fisrtName = params.row.attributes.first_name ? params.row.attributes.first_name : ""
        const lastName = params.row.attributes.last_name ? params.row.attributes.last_name : ""
        const FEname = fisrtName + " " + lastName
        return (
          <Tooltip title={FEname}>
            <Typography className="customRowFontNameFarmer" noWrap>
              {FEname}
            </Typography>
          </Tooltip>
        )
      },
    },
    {
      field: "noOfFarmers",
      sortable: false,
      headerName: "No. of Farmers",
      type: "number",
      align: "center",
      width: 90,
      headerAlign: "center",
      headerClassName: "customColHeaderUpdated",
      renderCell: (params: GridRenderCellParams) => {
        const noOfFarmer = params.row.attributes?.number_of_farmers ? params.row.attributes?.number_of_farmers : "-"
        return (
          <Tooltip title={noOfFarmer}>
            <Typography className="customRowFont" noWrap>
              {noOfFarmer}
            </Typography>
          </Tooltip>
        )
      },
    },
    {
      field: "villages",
      sortable: false,
      minWidth: 70,
      headerName: "Villages",
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "customColHeader",
      renderCell: (params: GridRenderCellParams) => {
        const villagesNames = params.row?.attributes.accounts_villages?.length ?
          params.row.attributes.accounts_villages.map((each: any) => each.attributes?.village_name) : [];

        const allVillages = villagesNames.join(", ");
        return (
          <>
            <Tooltip title={allVillages}>
              <Typography className="customRowFont" noWrap>
                {allVillages}
              </Typography>
            </Tooltip>
          </>
        )
      },
    },
    {
      field: "states",
      sortable: false,
      headerName: "States",
      minWidth: 150,
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "customColHeader",
      renderCell: (params: GridRenderCellParams) => {
        const stateNames = params.row.attributes.states.length
          ? params.row.attributes.states.map((each: any) => each?.name)
          : [];
        const allStates = stateNames.join(", ");
        return (
          <Tooltip title={allStates}>
            <Typography className="customRowFont" noWrap>
              {allStates}
            </Typography>
          </Tooltip>
        )
      },
    },
    {
      field: "role",
      sortable: false,
      headerName: "role",
      width: 120,
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "customColHeader",
      renderCell: (params: GridRenderCellParams) => {
        const FErole = params.row.attributes.role_id === 1 ? "Field Executive" : ""
        return (
          <Tooltip title={FErole}>
            <Typography className="customRowFont" noWrap>
              {FErole}
            </Typography>
          </Tooltip>
        )
      },
    },
    {
      field: " ",
      sortable: false,
      headerName: "",
      headerClassName: "customColHeader",
      headerAlign: "center",
      align: "right",
      minWidth: 130,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <>
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "18px",
              marginRight: '12px',
              alignItems:"center" 
            }}
          >
            <Tooltip title="Preview">
              <Link href={`/view-fe-profile/${params.row.id}`} >
                <IconButton
                  aria-label="preview"
                  disableRipple
                  style={{ padding: "0px" }}
                  disabled
                >
                  <Visibility
                    fontSize="small"
                    style={{
                      cursor: "pointer",
                      color: "#006A77",
                    }}
                  />
                </IconButton>
              </Link>
            </Tooltip>
            <Tooltip title="Edit">
              <IconButton
                aria-label="edit"
                disableRipple
                style={{ padding: "0px" }}
                onClick={(e) => {
                  handleFeEditClick(params.row);
                }}
              >
                <EditIcon
                  fontSize="small"
                  style={{
                    cursor: "pointer",
                    color: "#EB8F06",
                  }}
                />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton
                aria-label="delete"
                disableRipple
                style={{ padding: "0px" }}
                data-testid={`tableDeleteIconBtn-${params.row.id}`}
                onClick={(e) => {
                  handleFEDelete(params.row);
                }}
              >
                <DeleteIcon
                  fontSize="small"
                  style={{
                    cursor: "pointer",
                    color: "#B3261E",
                  }}
                />
              </IconButton>
            </Tooltip>
          </Box>
        </>
      ),
    },
  ];

export const adminColumns = (
  handleAdminEditClick: (data: any) => void,
  handleAdminDelete: (data: any) => void,
  isLoggedUserAdmin: boolean
): GridColumns => [
    {
      field: "name",
      sortable: false,
      headerName: "Name",
      minWidth: 160,
      align: "left",
      flex: 1,
      headerAlign: "center",
      headerClassName: "customColHeader",
      renderCell: (params: GridRenderCellParams) => {
        let firstName = params.row?.first_name ? params.row?.first_name : ""
        let lastName = params.row?.last_name ? params.row?.last_name : ""
        let adminName = firstName + " " + lastName

        return (
          <Tooltip title={adminName}>
            <Typography className="customRowFontName" noWrap>
              {adminName}
            </Typography>
          </Tooltip>
        )
      },
    },
    {
      field: "mobileNumber",
      sortable: false,
      headerName: "Mobile Number",
      type: "number",
      align: "center",
      flex: 1,
      minWidth: 180,
      headerAlign: "center",
      headerClassName: "customColHeader",
      renderCell: (params: GridRenderCellParams) => {
        let phoneNumber = params.row?.phone_number ? "+91 " + params.row.phone_number : ""

        return (
          <Tooltip title={phoneNumber}>
            <Typography className="customRowFont" noWrap>
              {phoneNumber}
            </Typography>
          </Tooltip>
        )
      },
    },
    {
      field: "role",
      sortable: false,
      headerName: "role",
      flex: 1,
      minWidth: 150,
      align: "center",
      headerAlign: "center",
      headerClassName: "customColHeader",
      renderCell: (params: GridRenderCellParams) => {
        let role = "";
        if (params.row?.role === "admin") {
          role = "Admin";
        }
        return (
          <Tooltip title={role}>
            <Typography className="customRowFont">{role}</Typography>
          </Tooltip>
        );
      },
    },
    {
      field: " ",
      sortable: false,
      headerName: "",
      flex: 1,
      headerClassName: "customColHeader",
      headerAlign: "center",
      minWidth: 130,
      align: "right",
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "18px",
              marginRight: "12px",
              alignItems:"center" 
            }}
          >
            <Tooltip title="Preview">
              <Link href={`/view-admin-profile/${params.row.id}`}>
                <IconButton
                  aria-label="preview"
                  disableRipple
                  style={{ padding: "0px" }}
                  disabled
                >
                  <Visibility
                    fontSize="small"
                    style={{
                      cursor: "pointer",
                      color: "#006A77",
                    }}
                  />
                </IconButton>
              </Link>
            </Tooltip>
            <Tooltip title="Edit">
              <IconButton
                aria-label="edit"
                disableRipple
                style={{ padding: "0px" }}
                data-testid={`tableEditIconBtn-${params.row.id}`}
                onClick={(e) => {
                  handleAdminEditClick(params.row);
                }}
              >
                <EditIcon
                  fontSize="small"
                  style={{
                    cursor: "pointer",
                    color: "#EB8F06",
                  }}
                />
              </IconButton>
            </Tooltip>
            {!isLoggedUserAdmin ? (
              <Tooltip title="Delete">
                <IconButton
                  aria-label="delete"
                  disableRipple
                  style={{ padding: "0px" }}
                  data-testid={`tableDeleteIconBtn-${params.row.id}`}
                  onClick={(e) => {
                    handleAdminDelete(params.row);
                  }}
                  disabled={isLoggedUserAdmin}
                >
                  <DeleteIcon
                    fontSize="small"
                    style={{
                      cursor: "pointer",
                      color: "#B3261E",
                    }}
                  />
                </IconButton>
              </Tooltip>
            ) : (
              <></>
            )}
          </Box>
        );
      },
    },
  ];

export const superAdminColumns = (
  handleSuperAdminEditClick: (data: any) => void,
  handleAdminDelete: (data: any) => void,
  isLoggedUserAdmin: boolean
): GridColumns => [
    {
      field: "name",
      sortable: false,
      headerName: "Name",
      minWidth: 160,
      align: "left",
      flex: 1,
      headerAlign: "center",
      headerClassName: "customColHeader",
      renderCell: (params: GridRenderCellParams) => {
        let firstName = params.row?.first_name ? params.row?.first_name : ""
        let lastName = params.row?.last_name ? params.row?.last_name : ""
        let superAdminName = firstName + " " + lastName

        return (
          <Tooltip title={superAdminName}>
            <Typography className="customRowFontName" noWrap>
              {superAdminName}
            </Typography>
          </Tooltip>
        )
      },
    },
    {
      field: "mobileNumber",
      sortable: false,
      headerName: "Mobile Number",
      type: "number",
      align: "center",
      flex: 1,
      minWidth: 180,
      headerAlign: "center",
      headerClassName: "customColHeader",
      renderCell: (params: GridRenderCellParams) => {
        let phoneNumber = params.row?.phone_number ? "+91" + params.row.phone_number : ""
        return (
          <Tooltip title={phoneNumber}>
            <Typography className="customRowFont" noWrap>
              {phoneNumber}
            </Typography>
          </Tooltip>
        )
      },
    },
    {
      field: "role",
      sortable: false,
      headerName: "role",
      flex: 1,
      minWidth: 150,
      align: "center",
      headerAlign: "center",
      headerClassName: "customColHeader",
      renderCell: (params: GridRenderCellParams) => {
        let role = "";
        if (params.row?.role === "super_admin") {
          role = "Super Admin";
        }
        return (
          <Tooltip title={role}>
            <Typography className="customRowFont" noWrap>{role}</Typography>
          </Tooltip>)
      },
    },
    {
      field: " ",
      sortable: false,
      headerName: "",
      flex: 1,
      headerClassName: "customColHeader",
      headerAlign: "center",
      align: "right",
      minWidth: 130,
      renderCell: (params: GridRenderCellParams) => (
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "18px",
            marginRight: "12px",
            alignItems:"center" 
          }}
        >
          <Tooltip title="Preview">
            <Link href={`/view-super-admin-profile/${params.row.id}`}>
              <IconButton
                aria-label="preview"
                disableRipple
                style={{ padding: "0px" }}
                disabled
              >
                <Visibility
                  fontSize="small"
                  style={{
                    cursor: "pointer",
                    color: "#006A77",
                  }}
                />
              </IconButton>
            </Link>
          </Tooltip>

          {!isLoggedUserAdmin ? (
            <>
              <Tooltip title="Edit">
                <IconButton
                  aria-label="edit"
                  disableRipple
                  style={{ padding: "0px" }}
                  data-testid={`tableEditIconBtn-${params.row.id}`}
                  onClick={(e) => {
                    handleSuperAdminEditClick(params.row);
                  }}
                >
                  <EditIcon
                    fontSize="small"
                    style={{
                      cursor: "pointer",
                      color: "#EB8F06",
                    }}
                  />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete">
                <IconButton
                  aria-label="delete"
                  disableRipple
                  style={{ padding: "0px" }}
                  data-testid={`tableDeleteIconBtn-${params.row.id}`}
                  onClick={(e) => {
                    handleAdminDelete(params.row);
                  }}
                  disabled={isLoggedUserAdmin}
                >
                  <DeleteIcon
                    fontSize="small"
                    style={{
                      cursor: "pointer",
                      color: "#B3261E",
                    }}
                  />
                </IconButton>
              </Tooltip>
            </>
          ) : (
            <></>
          )}
        </Box>
      ),
    },
  ];

export const selectMenuItem = (title: string) => {
  return (
    <MenuItem value={""} disabled>
      <span className="opcity-48">{title}</span>
    </MenuItem>
  )
}
export const NoMenuDataFound = () => {
  return(
    <MenuItem disabled>- No data found</MenuItem>
  )
}

export const villageNameAvatar = (index: number, option: any) => {
  return (
    <>
      <Avatar
        style={{
          backgroundColor: `${index % 2 === 0 ? "#108B88" : "#EB8F06"}`,
          color: "#fff",
          height: "28px",
          width: "28px",
          fontSize: "12px",
          fontStyle: "normal",
          fontWeight: 500,
          lineHeight: "18px",
        }}
      >
        {option?.name?.charAt(0).toUpperCase()}
      </Avatar>
    </>
  );
};
export const getCustomErrorMessage = (touched: any, errors: any, value: string) => {
  return (
    touched[value] &&
    errors[value] && (
      <div>
        <Typography className="text-danger" style={{ fontSize: "0.75rem" }}>
          {errors[value]}
        </Typography>
      </div>
    )
  );
};

export const getErrorMessageBoolean = (touched: any, errors: any, value: string) => {
  return touched[value] && Boolean(errors[value]);
};


export const isImage = (url: string | null) => url && /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);

export function renderMessageContent(message: string, handleShareLinkPopup?: any) {
  const words = message.split(" ");
  let messageContent = words.map((item) => {
      if (item.startsWith("https://co:farm")) {
          return <a onClick={() => {
              let linkType = item.split("/")[3]

              if(linkType === 'cropcalendar') {
                let subActivityProgressId = item.split("/")[5]
                handleShareLinkPopup(linkType, subActivityProgressId)
              } else {
                let farmType = item.split("/")[4]
                let linkId = item.split("/")[5]
                let accountId = item.split("/")[6]
                handleShareLinkPopup(linkType, null, linkId, accountId, farmType)
              }
          }} style={{ textDecoration: "underline", lineHeight: '22px', color: '#006A77', marginTop: '4px', cursor: 'grabbing' }}>{item}</a>
      } else if (item.startsWith("https://" || "http://")) {
          return <a href={item} style={{ textDecoration: "underline", 
                                         lineHeight: '22px', 
                                         color: '#006A77', 
                                         marginTop: '4px', 
                                         cursor: 'grabbing' 
                                        }} 
                                target="_blank"> {item} </a>
      } else {
          return " " + item + " "
      }
  })
  return messageContent
}

export function handleLinkShare (item:any,handleShareLinkPopup:any) {
  let linkType = item?.attributes?.link_share.split("/")[3];

  if(linkType === 'cropcalendar') {
    let subActivityProgressId = item?.attributes?.link_share.split("/")[5];
    handleShareLinkPopup(linkType, subActivityProgressId);
  } else {
    let farmType = item?.attributes?.link_share.split("/")[4];
    let linkId = item?.attributes?.link_share.split("/")[5];
    let accountId = item?.attributes?.link_share.split("/")[6];
    handleShareLinkPopup(linkType, null, linkId,accountId,farmType)
  }
}

export const totalAreaCottonValue = (totalAreaOfCotton: any) => {
  const cottonArea = totalAreaOfCotton ? totalAreaOfCotton : 0.00
  const cottonAreaPrecise = parseFloat(cottonArea).toPrecision(3)
  return (cottonAreaPrecise + " " + "Acres")
}

export const LoaderCenter = ({
  loadingStyle,
}: {
  loadingStyle?: React.CSSProperties;
}) => {
  return (
    <>
      <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "10px 0" }}>
        <CircularProgress style={{ color: "#df5200", ...loadingStyle }} />
      </Box>
    </>
  )
}
export const DataNotFound = ({
  fontStyle={},
}: {
  fontStyle?: React.CSSProperties;
}) => {
  return (
      <Typography style={fontStyle}>No Data Found!</Typography>
  )
}

export const renderErrorMessage = (errorMessage:string) => {
  return (
    errorMessage ? (
      <div>
        <Typography style={{ fontSize: "0.75rem",color:"#f44336" }}>
          {errorMessage}
        </Typography>
      </div>
    ):<></>
  );
};


export const LoadingButton = ({
  btnStyle,
  loadingStyle,
  title,
  isLoading = false,
  onClick,
}: {
  btnStyle: React.CSSProperties;
  loadingStyle: React.CSSProperties;
  title: string;
  isLoading?: boolean;
  onClick: () => void,
}) => {
  return (
      <Button disabled={isLoading} style={btnStyle} onClick={onClick}>
          {isLoading ? <CircularProgress style={loadingStyle} /> : title}
      </Button>
  )
}

export const HTMLView: React.FC<{ content: string }> = ({ content }) => {
  return (
    <StyledHTMLView dangerouslySetInnerHTML={{ __html: content }} />
  );
}
const StyledHTMLView = styled(Box)({
  "fontFamily": "IBM Plex Sans, Roboto, Helvetica, Arial, sans-serif",

  "p, h1, h2, h3, h4, h5, h6": {
    "fontFamily": "inherit"
  }
});
