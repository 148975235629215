import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgBell } from "./assets";
import { isImage } from "../../../components/src/AdminConsoleHelper";
import * as Yup from 'yup';
import { getStorageData } from "../../../framework/src/Utilities";

export interface InitalvaluesType {
  tab: 0 | 1 | 2,
  notification_type_id: string,
  chips: any[],
  image: string,
  isImage: boolean,
  originalImg: string,
  data: Array<{
    id?: string
    title: string,
    language: number,
    description: string,
  }>
}

type NotificationType = null | Array<{
    "id": number,
    "type": string,
    "attributes": {
        "id": number,
        "name": string,
        "name_hindi": string,
        "name_gujrati": string,
    }
}>
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  popupTitle: string;
  error: string | null;
  expandedAccordion : string;
  notification: any[];
  data: any[];
  selectedData: any;
  token: any;
  activeTabs: string;
  initalvalues: InitalvaluesType;
  notificationType : NotificationType;
  anchorEl : any;
  selectedState: any;
  selectedDistrict: any;
  selectedTaluka: any;
  selectedVillage: any;
  selectedGroup: any;
  selectedFE: any;
  selectedFarmer: any;
  stateList: any;
  districtList: any;
  talukaList: any;
  villageList: any;
  groupList: any;
  FEList: any;
  farmerList: any;
  expanded: string | null;
  inputField: string;
  isLoading: boolean;
  allFilter: boolean;
  chips: any[];
  isEdit : string | null;
  deletePopup: string | null;
  isShare: boolean,
  linkShare:string,
  messageHeading:string,
  isNotifyLInk:boolean,
  notiFyType:string,
  showNotificationObj:any,
  page:number,
  per_page:number,
  notificationLoading:boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class NotificationsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getDataCallId: string = "";
  markAsReadCallId: string = "";
  deleteCallId: string = "";
  notificationCallId: string = "";
  notificationTypeCall: string = "";
  createNotificationCallId: string = "";
  updateNotificationCallId: string = "";
  statesFieldCallId : string = "";
  districtFieldCallId : string = "";
  talukaFieldCallId : string = "";
  villageFieldCallId : string =  "";
  groupFieldCallId : string = "";
  fieldExecutiveCallId: string = "";
  farmerCallId : string = "";
  notificationDeleteCallId: string = "";
  fetchNotificationApiCallId: string = ""

  validationSchema = Yup.object().shape({
    notification_type_id: Yup.string().test("notification_type_id", "Please select the Type", (value) => value !== 'none'),
    image: Yup.string().required('Please upload the Image/Video'),
    data: Yup.array().of(Yup.object().shape({
      title: Yup.string().required("Enter the title"),
      language: Yup.number(),
      description: Yup.string().required("Enter the description"),
    }))
  })

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      popupTitle: "Error",
      error: null,
      deletePopup: null,
      expandedAccordion : "",
      isEdit: null,
      notification: [],
      chips: [],
      isLoading: false,
      data: [],
      notificationType: null,
      selectedData: null,
      token: "",
      activeTabs: 'sent',
      initalvalues: {...configJSON.createParam},
      anchorEl: null,
      selectedState: {...configJSON.initalParams("State")},
      selectedDistrict: {...configJSON.initalParams("District")},
      selectedTaluka: {...configJSON.initalParams("Taluka")},
      selectedVillage: {...configJSON.initalParams("Village")},
      selectedGroup: {...configJSON.initalParams("Group")},
      selectedFE: {...configJSON.initalParamsFirstName("Field Executive")},
      selectedFarmer: {...configJSON.initalParamsFirstName("Farmer")},
      stateList: [],
      districtList: [],
      talukaList: [],
      villageList: [],
      groupList: [],
      FEList: [],
      farmerList: [],
      expanded: null,
      inputField: "",
      allFilter: false,
      isShare: false,
      linkShare:'',
      messageHeading:'',
      isNotifyLInk:false,
      notiFyType:'',
      showNotificationObj:{},
      page:1,
      per_page:10,
      notificationLoading:false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    this.getNotificationType();
    this.getNotificationList();
    this.getStateList();
    this.getDistrictList();
    this.getTalukaList();
    this.getGroupList();
    this.getVillageList();
    this.getFieldExecutiveList();
    this.getFarmerList();
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    const apiResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const errorResponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
     )

     const msgID = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      runEngine.debugLog("TOKEN", token);
      this.setState({ token: token });
      this.getNotifications();
    }
 

     if(apiResponse && !apiResponse.errors) {
      switch(msgID) {
        case this.getDataCallId: {
          return this.setState({data: apiResponse.data});
        }
        case this.markAsReadCallId: {
           return this.getNotifications();
        }  
        case this.deleteCallId: {
          return this.handleDeleteCallId(apiResponse) 
        }
        case this.notificationCallId: {
          return   this.setState({ notificationLoading:false ,notification: [...this.state.notification,...apiResponse ],page:this.state.page+1})
        }
        case this.notificationTypeCall: {
           return this.setState({ notificationType: apiResponse.data })
         }
        case this.statesFieldCallId: {
          return this.setState({ stateList: apiResponse })
        }
        case this.districtFieldCallId: {
          return this.setState({ districtList: apiResponse })
        }
        case this.villageFieldCallId: {
          return this.setState({ villageList: apiResponse })
        }
        case this.groupFieldCallId: {
          return this.setState({ groupList: apiResponse })
        }
        case this.talukaFieldCallId: {
          return this.setState({ talukaList: apiResponse })
        }
        case this.notificationDeleteCallId:{
          return this.handleDeleteCallId(apiResponse)
        }
        case this.fieldExecutiveCallId:{
          return this.setState({ FEList: apiResponse })
        }
        case this.farmerCallId:{
          return this.setState({ farmerList: apiResponse })
        }
        case this.createNotificationCallId:
        case this.updateNotificationCallId: {
          return this.setState({
            isLoading: false, chips: [], isEdit: null, error: ` Notification ${this.state.isEdit ? "Updated" : "Added"} successfully`,
            popupTitle:  "Successful "
            , page: 1, notification: []
          }, () => {
            this.getNotificationList()
            this.setState({ initalvalues: { ...configJSON.createParam } })
            window.scrollTo(0, 0)
          })
        }
        case this.fetchNotificationApiCallId:
          let data: any = apiResponse.data
          this.setState({
            showNotificationObj:data?.attributes
           }) 
        break;
      }
     } else if(errorResponse || apiResponse) {
      this.errorHandler(errorResponse || apiResponse)
     }
    // Customizable Area End
  }

  // Customizable Area Start
  iconBellProps = {
    source: imgBell,
  };

  opacityCalculator = (placeHolder : string) => placeHolder.includes("Select ") ? 0.48  : 1; 


  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<S>): void{
      if (prevState.selectedState !== this.state.selectedState) {
        this.getDistrictList()
        this.getGroupList()
        this.getFieldExecutiveList()
        this.getFarmerList()
      }
      if (prevState.selectedDistrict !== this.state.selectedDistrict) {
        this.getTalukaList()
        this.getGroupList()
        this.getFarmerList()
        this.getFieldExecutiveList()
      }
      if (prevState.selectedTaluka !== this.state.selectedTaluka) {
        this.getVillageList()
        this.getGroupList()
        this.getFarmerList()
        this.getFieldExecutiveList()
      }
      if (prevState.selectedVillage !== this.state.selectedVillage) {
        this.getFieldExecutiveList()
        this.getGroupList()
        this.getFarmerList()
      }
      if (prevState.selectedVillage !== this.state.selectedVillage) {
        this.getGroupList()
      }
      if (prevState.selectedFE !== this.state.selectedFE) {
        this.getFarmerList()
      }
  }

  handleShareLink =(notiFyObj?:any) => {  
   const shareLink = configJSON.notificationDetailsShareLink + notiFyObj?.type +'/'+ notiFyObj?.id
    const heading =  this.state.activeTabs === 'sent' ? 'Sent Notification' : 'Received Notification'
    this.setState({
      isShare : !this.state.isShare,
      linkShare:shareLink,
      messageHeading:heading
    })   
  }

  handleShowNotificationPopup =(linkType?:any,linkId?:any,accountId?:any,type?:any) => { 
    this.setState({
      isNotifyLInk: !this.state.isNotifyLInk,
      notiFyType:type,
      showNotificationObj: {}
    })
    this.getNotificationPopupData(linkId,type) 
  }

  getNotificationPopupData = async (notifyId: string | number,type: string)=>{
    if (!notifyId) return;
    const endPoint = `${type === 'admin_notification_web' ? configJSON.getNotificationAdminApiEndPoint : configJSON.getNotificationPushApiEndPoint}${notifyId}`;
    this.fetchNotificationApiCallId = await this.apiCall(configJSON.getDataMethod, endPoint);  
  }

  selectedLanguage = (tab : number) => {
    if(tab === 2) return "name_gujrati";
    if (tab === 1) return "name_hindi" ;
    return "name"
  }

  getNotificationList = async () => {
    this.setState({ notificationLoading: true })
      this.notificationCallId = await this.apiCall(configJSON.getDataMethod, configJSON.listNotificationEndPoint(this.state.activeTabs,this.state.page,this.state.per_page))
  }

toggleDeletePopup = (deletePopup : string | null) => this.setState({ deletePopup })


  getStateList = async () => {
    this.statesFieldCallId = await this.apiCall(configJSON.getDataMethod , configJSON.getStateList);
  }

  getDistrictList = async () => {
    const { selectedState } = this.state
    const endPoint = `${configJSON.getDistrictList}?state_id=${selectedState.id}`;
    this.districtFieldCallId = await this.apiCall(configJSON.getDataMethod , endPoint);
  }

  getTalukaList = async () => {
    const { selectedDistrict } = this.state
    const endPoint = `${configJSON.getTalukaList}?district_id=${selectedDistrict.id}`;
    this.talukaFieldCallId = await this.apiCall(configJSON.getDataMethod , endPoint);
  }

  getVillageList = async () => {
    const { selectedTaluka } = this.state
    const endPoint = `${configJSON.getVillageList}?taluka_id=${selectedTaluka.id}`;
    this.villageFieldCallId = await this.apiCall(configJSON.getDataMethod , endPoint);
  }

  getGroupList = async () => {
    this.groupFieldCallId = await this.apiCall(configJSON.getDataMethod , configJSON.getGroupList);
  }

  getFieldExecutiveList = async () => {
    const { selectedDistrict , selectedState , selectedTaluka , selectedVillage } = this.state
    const endPoint = `${configJSON.getFieldExecutiveList}?state_id=${selectedState.id}&district_id=${selectedDistrict.id}&taluka_id=${selectedTaluka.id}&village_id=${selectedVillage.id}`
    this.fieldExecutiveCallId = await this.apiCall(configJSON.getDataMethod , endPoint);
  }

  getFarmerList = async () => {
    const { selectedDistrict , selectedState , selectedTaluka , selectedVillage, selectedFE } = this.state
    const endPoint = `${configJSON.getFarmerList}?state_id=${selectedState.id}&district_id=${selectedDistrict.id}&taluka_id=${selectedTaluka?.id}&village_id=${selectedVillage.id}&&field_executive_id=${selectedFE.id}`;
    this.farmerCallId = await this.apiCall(configJSON.getDataMethod , endPoint);
  }

  handleChangePanel = (panel: string) => this.setState((prevState) => ({ expanded: prevState.expanded !== panel ? panel : null, inputField: ""}));

  handleSearch = (type: string): boolean => (!this.state.inputField || type.toLowerCase().includes(this.state.inputField.toLowerCase()));


  getTypeChip = (type: number | undefined, role?: boolean) => {
    if(!type) return role ? "group" : "name" ;
    if(type === 1) return role ?  "FE" : "user_name";
    return role ? 'farmer' : "first_name";
  }

  handleChipDelete = (chipToDelete: any) => () => {
    this.setState((prevState) => ({
      chips: prevState.chips.filter((chip) => chip !== chipToDelete),
    }));
  };

  toggleAccordion = (id : string) => this.setState((preState) => ({ expandedAccordion : preState.expandedAccordion ===  id ? "" : id }))



  handleSubmitAccordion = () => {
    let chipsArr1: any[] = []

    if(this.state.allFilter || this.state.selectedState.id || this.state.selectedTaluka.id || this.state.selectedDistrict.id || this.state.selectedVillage.id){
       chipsArr1 = this.state.farmerList.concat(this.state.FEList, this.state.groupList);
    }
  
    if (this.state.selectedGroup.name !== "Select Group") {
      chipsArr1  = [this.state.selectedGroup];
    }
  
    if (this.state.selectedFE.first_name !== "Select Field Executive") {
      chipsArr1 = [this.state.selectedFE].concat(this.state.farmerList)
    }
    
    if (this.state.selectedFarmer.first_name !== "Select Farmer") {
      chipsArr1 = [this.state.selectedFarmer]
    }
  
    const chipsArr = this.state.chips.concat([...chipsArr1])
  
    this.setState(() => ({
      chips: chipsArr.filter((obj, index) => 
          index === chipsArr.findIndex(objItem => obj.id === objItem.id)
      ),
    }), () => this.clearFilter());
  }

  deleteEvent = async () =>  {
    this.notificationDeleteCallId = await this.apiCall(configJSON.deleteText, `${configJSON.deleteHolder}${this.state.deletePopup}`);
  }

  stateSubSelected = () =>  (!!this.state.selectedDistrict.id || !!this.state.selectedFE.id || !!this.state.selectedFarmer.id || !!this.state.selectedGroup.id || !!this.state.selectedTaluka.id || !!this.state.selectedVillage.id);

  districtSubSelected = () => (!!this.state.selectedFE.id || !!this.state.selectedFarmer.id || !!this.state.selectedGroup.id || !!this.state.selectedTaluka.id || !!this.state.selectedVillage.id);

  talukaSubSelected = () => (!!this.state.selectedFE.id || !!this.state.selectedFarmer.id || !!this.state.selectedGroup.id ||  !!this.state.selectedVillage.id);

  villageSubSelected = () => (!!this.state.selectedFE.id || !!this.state.selectedFarmer.id || !!this.state.selectedGroup.id );

  checkDisabled = (type : string) => {
       if(this.state.allFilter){
          return true;
       }
       if(type === 'State'  && this.stateSubSelected()){
        return true;
       }
       if(type === 'District' && this.districtSubSelected()){
         return true;
       }
       if(type === 'Taluka' && ((!!this.state.selectedState.id  && !this.state.selectedDistrict.id) || this.talukaSubSelected())){
         return true
       }
       if(type === 'Village' && ((!!this.state.selectedState.id  && !this.state.selectedTaluka.id) ||  this.villageSubSelected())){
         return true
       }
    return false;
  }

  getKey = (language: string) => {
      if(language === "Gujrati"){
        return 2;
      }
      return language === "Hindi" ? 1 : 0
  }

  editHandle = (data : any) => {
      const initValue = {...configJSON.createParam};
      initValue.notification_type_id = data?.attributes.notification_type.id;
      initValue.image = data?.attributes.image.url;
      initValue.isImage = isImage(data?.attributes.image.url);
      initValue.originalImg = data?.attributes.image.url;
      
      initValue.data = data?.attributes.language_notifications.map((formData: any) =>  ({...formData.attributes, language: this.getKey(formData.attributes.language)})).sort((pre: any, next: any) => pre.language - next.language);

       let chips: any[] = []
      if(data?.attributes.send_to){
        chips = chips.concat(this.state.farmerList.concat(this.state.FEList).filter((userData : { id: number }) => data?.attributes.send_to.includes(userData.id.toString())))
      }
      if(data?.attributes.send_to_groups){
        chips = chips.concat(this.state.groupList.filter((userData : { id: number }) => data?.attributes.send_to_groups.includes(userData.id.toString())))
      }
      
      this.setState({ initalvalues: initValue , chips, isEdit: data?.id })
  }
  
  removeEdit = () => this.setState({
    initalvalues: {...configJSON.createParam},
    chips: [],
    isEdit: null,
  })

  getNotifications() {
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage)); 

    this.getDataCallId = getDataMsg.messageId;

    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPoint
    );

    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        token: this.state.token ? this.state.token : "",
      })
    );

    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getDataMethod
    );

  }

  markAsRead(id: number) {
    const markAsReadMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.markAsReadCallId = markAsReadMsg.messageId;

    markAsReadMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.endPoint}/${id}`
    );

    markAsReadMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        token: this.state.token ? this.state.token : "",
      })
    );

    markAsReadMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.markAsReadMethod
    );

    runEngine.sendMessage(markAsReadMsg.id, markAsReadMsg);
  }

  deleteNotifications(id: number) {
    const deletedMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.deleteCallId = deletedMsg.messageId;

    deletedMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.endPoint}/${id}`
    );

    deletedMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        token: this.state.token ? this.state.token : "",
      })
    );

    deletedMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(deletedMsg.id, deletedMsg);
  }
  
  timeSince(date: string) {
    var seconds = Math.floor(
      (new Date().valueOf() - new Date(date).valueOf()) / 1000
    );
    var interval = seconds / 31536000;
    if (interval > 1) {
      return Math.floor(interval) + " years";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + " months";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + " days";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + " hours";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + " minutes";
    }
    return Math.floor(seconds) + " seconds";
  }
  convertDate(inputFormat: string) {
    function pad(s: any) {
      return s < 10 ? "0" + s : s;
    }
    var d = new Date(inputFormat);
    return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join("-");
  }

  getNotificationType = async () => {
    this.notificationTypeCall = await this.apiCall( configJSON.getDataMethod , configJSON.notificationType)
  }

  getErrors = (touched : any, errors : any, tab : number, key : string) => {
    return touched.data && touched.data[tab] && touched.data[tab][key] && errors?.data && errors.data[tab] &&  errors.data[tab][key]
  }

  clearFilter = (type?: boolean) => this.setState({
    allFilter: false,
    anchorEl: type ? this.state.anchorEl : null,
    expanded: null,
    selectedState : {...configJSON.initalParams("State")},
    selectedVillage: {...configJSON.initalParams("Village")},
    selectedDistrict: {...configJSON.initalParams("District")},
    selectedGroup: {...configJSON.initalParams("Group")},
    selectedTaluka: {...configJSON.initalParams("Taluka")},
    selectedFE: {...configJSON.initalParamsFirstName("Field Executive")},
    selectedFarmer: {...configJSON.initalParamsFirstName("Farmer")},
  })
  


  errorHandler = (errorResponse?: {errors: string[] | string } ) => {
    let error = "Something went wrong";
    if(errorResponse?.errors){
      error = typeof(errorResponse.errors) === "string" ?  errorResponse.errors  :  errorResponse.errors[0]
    }
    if(!error.includes('no admin')){
      this.setState({
        error,
        isLoading: false,
        popupTitle: "Error"
    }, () =>  window.scrollTo(0,0));
    }
    this.setState({
      notificationLoading: false,
      notification: [...this.state.notification]
    })
  }
 

  apiCall = async(method: string, endpoint: string, body?: string, withoutJsonHeader?: boolean) => {
    const header: {
      token: string,
      "Content-Type"?: string,
    } = {
      token:  await getStorageData("tokenn"),
    };

    if(!withoutJsonHeader){
      header["Content-Type"] = "application/json"
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    if (body) {
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    }

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;

  }
  
  toggleBtn = (tab : string | null) => tab && this.setState({  activeTabs : tab, notification: [],page:1 }, () => this.getNotificationList());

  getLanguage = (type : number) => {
    if(type === 2) { 
      return "Gujrati"
    }
    return type === 1 ? "Hindi" : "English";
  }

  submitHandle = (values : InitalvaluesType, reset : any) => {
  const { chips } = this.state
  this.setState({ isLoading: true }, () => reset())

  const formData = new FormData();
  
  formData.append("data[attributes][notification_type_id]", values.notification_type_id);
  chips.filter((data) => !data.chat_type).forEach((data) =>  formData.append(`data[attributes][send_to][]`, data?.id))
  chips.filter((data) => data.chat_type).forEach((data) =>  formData.append(`data[attributes][send_to_groups][]`, data?.id))
  if(!formData.get("data[attributes][send_to][]")){
    formData.append(`data[attributes][send_to][]`, '')
  } 
  if(!formData.get("data[attributes][send_to_groups][]")){
    formData.append(`data[attributes][send_to_groups][]`, "")
  }

  formData.append("data[attributes][image]", values.originalImg);
  values.data.forEach((data , index) => {
    if(data.id){
      formData.append(`data[attributes][language_notifications_attributes][${index}][id]`, data.id );  
    }
    formData.append(`data[attributes][language_notifications_attributes][${index}][language]`, this.getLanguage(data.language));
    formData.append(`data[attributes][language_notifications_attributes][${index}][title]`, data.title);
    formData.append(`data[attributes][language_notifications_attributes][${index}][description]`, data.description);
  })
  

   if(this.state.isEdit){
    this.updateNotification(formData)
    return ;
   }
 
    this.createNotification(formData);
  }


  createNotification  = async(payload : any) => {
   this.createNotificationCallId = await this.apiCall(configJSON.postMethod , configJSON.createNotification , payload, true)
  }

  updateNotification  = async(payload : any) => {
   this.updateNotificationCallId = await this.apiCall(configJSON.putMethod , `${configJSON.createNotification}/${this.state.isEdit}` , payload, true)
  }

   errorCheck= (error: any , setFieldValue: (key : string , value : any) => {}) => {
      if(Object.keys(error).length === 0)  return ;
      document.getElementById('name')?.scrollIntoView({ behavior: 'smooth' })
      if (error.notification_type_id  ||  error.image){
        return setFieldValue('tab', 0)
      }
      const tabLang = error.data.findIndex((data : any) =>  data)
      return setFieldValue('tab', tabLang)
  }

  errorImg = (touched: any , errors: any) =>  errors.originalImg || (touched.image && errors.image)

  setField = async (value: any , setFieldValue: (key: string , field : any) => void, setFieldError : (key: string , error : string ) => void) => {
    if(!configJSON.validFiles.includes(value?.type)){ return setFieldError("originalImg" , "Please upload a JPG, PNG image or MP4 video.") }
    if(value?.size >= configJSON.limitMb ){
     return setFieldError("originalImg" , "Max allowed size is 25MB")
    }
    setFieldValue('image', URL.createObjectURL(value))
    setFieldValue('originalImg', value)
    if(isImage(value) || value?.type.includes('image')){
      return setFieldValue('isImage', true);
    } 
    setFieldValue('isImage', false);
  }
  handleDeleteCallId = (apiResponse: { message: string }) => {
    if (apiResponse && apiResponse.message) {
      this.setState({
        isLoading: false,
        deletePopup: null,
        page: 1,
        notification: [],
        popupTitle: "Success", error: "Notification Deleted successfully."
      },
        () => this.getNotificationList())
      this.removeEdit()
    } else {
      this.setState({ popupTitle: "Error", error: "Something went wrong" });
    }
  }
  // Customizable Area End
}
