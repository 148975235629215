import moment from 'moment';
import * as Yup from 'yup';
import { IUnitDropdown, ISelectCrop, IDropdownDetailsData, IUnitMenu, IResponseDropdowns, ISimpleDropdownFormData, ICropLanguageObject, ICropErrorObject, IDefaultCropFormObj, IDefaultValueSimple, ILanguageObjectSimple, IDefaultValueUnit, IValueUnit, IInputAndLabel, IDefaultLibraryFAQ, ISubValueLibraryFAQ, FormDataType, IDropdownDetails, IDefaultFormTaluka, ILanguageObjectTaluka } from './interface.web';

const mobileRegEx = /^[0-9]{10}$/;
const alphaNumericRegex = /^[a-zA-Z0-9]+$/;
const usernameRegex = /^[a-zA-Z0-9@_]+$/;
const nameRegex = /^[A-Za-z]+(?: ?[A-Za-z])*$/;

// YUP-SCHEMA VALIDATIONS STARTS
export const SchemaFarmerForm = Yup.object().shape({
    firstnameFarmer: Yup.string()
        .trim()
        .min(2, "Minimum 2 characters are required")
        .max(50, "Maximum 50 characters are allowed")
        .required("Please enter First Name")
        .nullable(),
    middlenameFarmer: Yup.string()
        .trim()
        .min(1, "Minimum 1 characters are required")
        .max(50, "Maximum 50 characters are allowed")
        .required("Please enter Middle Name")
        .nullable(),
    surnameFarmer: Yup.string()
        .trim()
        .min(2, "Minimum 2 characters are required")
        .max(50, "Maximum 50 characters are allowed")
        .required("Please enter Surname Name")
        .nullable(),
    mobileNumberFarmer: Yup.string()
        .matches(mobileRegEx, "Please enter 10 digit Numbers Only")
        .required("Please enter Mobile Number")
        .nullable(),
    adharcardNumberFarmer: Yup.string()
        .matches(/^\d{12}$/, "Please enter 12 digit Number Only")
        // .required("Please enter Adharcard Number")
        .nullable(),
    dateOfBirthFarmer: Yup.date()
        .nullable()
        .typeError("Please enter valid Date")
        .test("valid-date", "Invalid Date", (value) => {
            if (!value) {
                return true; // Treat null as a valid input
            }
            return moment(value).isValid();
        })
        .max(
            moment().subtract(18, "years").add(1, "days").format("YYYY-MM-DD"),
            "Minimum age limit is 18 years"
        )
        .min(
            moment().subtract(120, "years").format("YYYY-MM-DD"),
            "Maximum age limit is 120 years"
        )
        .required("Date of Birth is required"),
    totalFamilyMemberFarmer: Yup.number()
        .integer("Value must be an number only.")
        .min(1, "Minimum required value is 1")
        .max(50, "Maximum allowed value is 50")
        .required("Please add Family Members")
        .nullable(),
    educationFarmer: Yup.string()
        .required("Please select Education")
        .nullable(),
    stateFarmer: Yup.string().required("Please select State").nullable(),
    genderFarmer: Yup.string().required("Please select Gender").nullable(),
    districtFarmer: Yup.string().required("Please select District").nullable(),
    talukaFarmer: Yup.string().required("Please select Taluka").nullable(),
    villageFarmer: Yup.string().required("Please select Village").nullable(),
    numberBelongsToFarmer: Yup.string().required("Please select value").nullable(),
    mobileTypeFarmer: Yup.string().required("Please select Mobile Type").nullable(),
});

export const SchemaFieldExecutiveForm = Yup.object().shape({
    firstnameFE: Yup.string()
        .trim()        
        .min(2, "Minimum 2 characters are required")
        .max(50, "Maximum 50 characters are allowed")
        .required("Please enter First Name")
        .nullable(),
    lastnameFE: Yup.string()
        .trim()        
        .min(2, "Minimum 2 characters are required")
        .max(50, "Maximum 50 characters are allowed")
        .required("Please enter Last Name")
        .nullable(),
    mobileNumberFE: Yup.string()
        .matches(mobileRegEx, "Please enter 10 digit Numbers Only")
        .required("Please enter Mobile Number")
        .nullable(),
    locationFE: Yup.string()
        .trim()        
        .min(2, "Minimum 2 characters are required")
        .max(50, "Maximum 50 characters are allowed")
        .nullable(),
    dateOfBirthFE: Yup.date()
        .nullable()
        .typeError("Please enter valid Date")
        .test("valid-date", "Invalid Date", (value) => {
            if (!value) {
                return true; // Treat null as a valid input
            }
            return moment(value).isValid();
        })
        .max(
            moment().subtract(18, "years").add(1, "days").format("YYYY-MM-DD"),
            "Minimum age limit is 18 years"
        )
        .min(
            moment().subtract(120, "years").format("YYYY-MM-DD"),
            "Maximum age limit is 120 years"
        )
        .required("Date of Birth is required"),
    emailAddressFE: Yup.string()
        .email("Please enter valid Email Address")
        .required("Please enter Email Address")
        .nullable(),
    usernameFE: Yup.string()
        .min(2, "Minimum 2 characters are required")
        .max(20, "Maximum 20 characters are allowed")
        .matches(
            usernameRegex,
            'Only alphanumeric characters, "@" and "_" are allowed'
        )
        .required("Please enter Username")
        .nullable(),
    mobileDeviceModelFE: Yup.string()
        .required("Please select Mobile Device")
        .nullable(),
});

export const SchemaAdminFormADD = Yup.object().shape({
    firstnameAdmin: Yup.string()
        .trim()
        .matches(nameRegex, "Only alphabets are allowed")
        .min(2, "Minimum 2 characters are required")
        .max(50, "Maximum 50 characters are allowed")
        .required("Please enter First Name")
        .nullable(),
    lastnameAdmin: Yup.string()
        .trim()
        .matches(nameRegex, "Only alphabets are allowed")
        .min(2, "Minimum 2 characters are required")
        .max(50, "Maximum 50 characters are allowed")
        .required("Please enter Last Name")
        .nullable(),
    mobileNumberAdmin: Yup.string()
        .matches(mobileRegEx, "Please enter 10 digit Numbers Only")
        .required("Please enter Mobile Number")
        .nullable(),
    emailAddressAdmin: Yup.string()
        .email("Please enter valid Email Address")
        .required("Please enter Email Address")
        .nullable(),
    usernameAdmin: Yup.string()
        .min(2, "Minimum 2 characters are required")
        .max(20, "Maximum 20 characters are allowed")
        .matches(
            usernameRegex,
            'Only alphanumeric characters, "@" and "_" are allowed'
        )
        .required("Please enter Username")
        .nullable(),
    dateOfBirthAdmin: Yup.date()
        .nullable()
        .typeError("Please enter valid Date")
        .test("valid-date", "Invalid Date", (value) => {
            if (!value) {
                return true; // Treat null as a valid input
            }
            return moment(value).isValid();
        })
        .max(
            moment().subtract(18, "years").add(1, "days").format("YYYY-MM-DD"),
            "Minimum age limit is 18 years"
        )
        .min(
            moment().subtract(120, "years").format("YYYY-MM-DD"),
            "Maximum age limit is 120 years"
        )
        .required("Date of Birth is required"),
    passwordAdmin: Yup.string()
        .min(6, "Minimum 6 characters are required")
        .max(20, "Maximum 20 characters are allowed")
        .required("Password is required")
        .nullable(),
    confirmPasswordAdmin: Yup.string()
        .min(6, "Minimum 6 characters are required")
        .max(20, "Maximum 20 characters are allowed")
        .oneOf([Yup.ref("passwordAdmin"), null], "Passwords must match")
        .required("Confirm Password is required")
        .nullable(),
});

export const SchemaAdminFormEDIT = Yup.object().shape({
    firstnameAdmin: Yup.string()
        .trim()
        .matches(nameRegex, "Only alphabets are allowed")
        .min(2, "Minimum 2 characters are required")
        .max(50, "Maximum 50 characters are allowed")
        .required("Please enter First Name")
        .nullable(),
    lastnameAdmin: Yup.string()
        .trim()
        .matches(nameRegex, "Only alphabets are allowed")
        .min(2, "Minimum 2 characters are required")
        .max(50, "Maximum 50 characters are allowed")
        .required("Please enter Last Name")
        .nullable(),
    mobileNumberAdmin: Yup.string()
        .matches(mobileRegEx, "Please enter 10 digit Numbers Only")
        .required("Please enter Mobile Number")
        .nullable(),
    emailAddressAdmin: Yup.string()
        .email("Please enter valid Email Address")
        .required("Please enter Email Address")
        .nullable(),
    usernameAdmin: Yup.string()
        .min(2, "Minimum 2 characters are required")
        .max(50, "Maximum 50 characters are allowed")
        .matches(
            usernameRegex,
            'Only alphanumeric characters, "@" and "_" are allowed'
        )
        .required("Please enter Username")
        .nullable(),
    dateOfBirthAdmin: Yup.date()
        .nullable()
        .typeError("Please enter valid Date")
        .test("valid-date", "Invalid Date", (value) => {
            if (!value) {
                return true; // Treat null as a valid input
            }
            return moment(value).isValid();
        })
        .max(
            moment().subtract(18, "years").add(1, "days").format("YYYY-MM-DD"),
            "Minimum age limit is 18 years"
        )
        .min(
            moment().subtract(120, "years").format("YYYY-MM-DD"),
            "Maximum age limit is 120 years"
        )
        .required("Date of birth is required"),
    passwordAdmin: Yup.string()
        .min(6, "Minimum 6 characters are required")
        .max(20, "Maximum 20 characters are allowed"),
    confirmPasswordAdmin: Yup.string()
        .when("passwordAdmin", {
            is: (value) => value && value.length > 1,
            then: Yup.string()
                .oneOf([Yup.ref("passwordAdmin"), null], "Passwords must match")
                .min(6, "Minimum 6 characters are required")
                .max(20, "Maximum 20 characters are allowed")
                .required("Please enter Confirm Password"),
            otherwise: Yup.string(),
        })
        .nullable(),
});
// YUP-SCHEMA VALIDATIONS ENDS

export const debounce = (cb: any, delay: any) => {
    let timer: any;
    return (...args: any) => {
        clearTimeout(timer);
        timer = setTimeout(() => cb(...args), delay);
    };
};

export const keyDown = (evt: any) =>
    ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault();
export const keyDownInt = (evt: any) =>
    ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault();

export const inputMinZero = {
    min:0
}

export const parseImages = (img?: string): string | undefined => {
    if (!img) return undefined;
    return img;
};
export function getBase64(file: any) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
}

export function getErrorMessage(touched: any, errors: any, value: string) {
    return touched[value] && Boolean(errors[value]);
};

export function getHelperText(touched: any, errors: any, value: string) {
    return touched[value] && errors[value];
};
export const returnArrayId = (data: any, keyName: string) => {
    return (data.attributes[keyName] && data.attributes[keyName].length && data.attributes[keyName][0]) ? data.attributes[keyName][0].id : "";
}

export const getUserFullName = (firstName: string, lastName: string) => {
    const fName = firstName ? firstName : ""
    const lName = lastName ? lastName : ""
    const fullName = (fName && lName) ? (firstName + " " + lastName) : fName ? fName : lName ? lName : ""
    return fullName
}

export function stateIDStatus(data: any): string {
    return data.attributes?.state_id?.length
      ? data.attributes?.state_id[0]?.id
      : "";
}

export function distIDStatus(data: any): string {
    return data.attributes.district_id && data.attributes.district_id.length
      ? data.attributes.district_id[0]?.id
      : "";
}

export function talukaIDStatus(data: any): string {
    return data.attributes.taluka_id && data.attributes.taluka_id.length
      ? data.attributes.taluka_id[0]?.id
      : "";
}

export function VillageIDStatus(data: any): string {
    return data.attributes.village_id && data.attributes.village_id.length
      ? data.attributes.village_id[0]?.id
      : "";
}

export function educationFarmerStatus(data: any): string {
    return data.attributes.highest_education_id &&
      data.attributes.highest_education_id.length
      ? data?.attributes?.highest_education_id[0]?.id
      : "";
}

export function farmnameFarmerStatus(data: any): string {
    return (
      (data.attributes.land_details &&
        data.attributes.land_details[0]?.attributes.name) ||
      ""
    );
}

export function totalFarmAreaFarmerStatus(data: any): string {
    return (
      (data.attributes.land_details &&
        data.attributes.land_details[0]?.attributes.farm_area) ||
      ""
    );
}

export function unitOfMeasureFarmerStatus(data: any): string {
    return data.attributes.land_details &&
      data.attributes.land_details[0]?.attributes.unit_farm_area_id
      ? data.attributes.land_details[0]?.attributes.unit_farm_area_id?.id
      : "";
}

export function horizontalDistanceFarmerStatus(data: any): string {
    return data.attributes.land_details && data.attributes.land_details.length
      ? data.attributes.land_details[0]?.attributes.horizontal_distance
      : "";
}

export function motorInHpFarmerStatus(data: any): string {
    return (
      (data.attributes.land_details &&
        data.attributes.land_details[0]?.attributes.motor_horse_power) ||
      ""
    );
}

export function depthOfPumpFarmerStatus(data: any): string {
    return (
      (data.attributes.land_details &&
        data.attributes.land_details[0]?.attributes.pump_depth) ||
      ""
    );
}

export function soilTextureFarmerStatus(data: any): string {
    return data.attributes.land_details &&
      data.attributes.land_details[0]?.attributes.soil_texture_id &&
      data.attributes.land_details[0]?.attributes.soil_texture_id.length &&
      data.attributes.land_details[0]?.attributes.soil_texture_id[0]?.id
      ? data.attributes.land_details[0]?.attributes.soil_texture_id[0]?.id
      : "";
}

export function sourceOfIrrigationFarmerStatus(data: any): string {
    return data.attributes.land_details &&
      data.attributes.land_details[0]?.attributes?.source_irrigation_id &&
      data.attributes.land_details[0]?.attributes?.source_irrigation_id
        .length &&
      data.attributes.land_details[0]?.attributes?.source_irrigation_id
      ? data.attributes.land_details[0].attributes.source_irrigation_id[0]?.id
      : "";
}

export function typeOfIrrigationFarmerStatus(data: any): string {
    return data.attributes.land_details &&
      data.attributes.land_details[0]?.attributes.type_irrigation_id &&
      data.attributes.land_details[0]?.attributes.type_irrigation_id.length &&
      data.attributes.land_details[0]?.attributes.type_irrigation_id[0]?.id
      ? data.attributes.land_details[0]?.attributes.type_irrigation_id[0]?.id
      : "";
}

export function farmSurveyNumberFarmerStatus(data: any): string {
    return data.attributes.land_details
      ? data.attributes.land_details[0]?.attributes.survey_number
      : "";
}

export function ownershipFarmerStatus(data: any): string {
    return data.attributes.land_details && data.attributes.land_details.length
      ? data.attributes.land_details[0]?.attributes.ownership_of_land_id?.id
      : "";
}

export function distanceInFarmerStatus(data: any): string {
    return data.attributes.land_details &&
      data.attributes.land_details[0]?.attributes.distance_in_id
      ? data.attributes.land_details[0]?.attributes.distance_in_id?.id
      : "";
}

export const checkUserIsLoogedIn = (userEmail: any) => {
    const loggedinUserEmail = localStorage.getItem("emailId")
    return String(userEmail) === String(loggedinUserEmail)
}

export const logoutUpdatedUser = () => {
    window.localStorage.clear()
    window.location.replace("/EmailAccountLoginBlock");
}

export const NavigationToHome = (userEmail: any, responseEmail: any) => {    
    const checkUserIsLoogedInBoolean = checkUserIsLoogedIn(userEmail)
    if (checkUserIsLoogedInBoolean) {
        const isLoggedInUserEmailUpdated = String(userEmail) !== String(responseEmail)
        if (isLoggedInUserEmailUpdated) {
            logoutUpdatedUser()
        }                        
    }
}

export function floorValueWithBase10(number: number) {
    if (isNaN(number)) {
        return 0;
    } else if (number >= 0) {
        return Math.floor(number / 10) * 10;
    } else {
        return Math.ceil(number / 10) * 10;
    }
}

export function ceilingValueWithBase10(number: number) {
    if (isNaN(number)) {
        return 0;
    } else if (number >= 0) {
        return Math.ceil(number / 10) * 10;
    } else {
        return Math.floor(number / 10) * 10;
    }
}

export function converStringToDate(date:string | Date) {
    if(date){
        return moment(date).format("DD MMM YYYY")
    }else {
        return ""
    }
}
export function returnTruthyString(value:string|number | null | undefined |"") {
    if(value){
        return String(value)
    }else {
        return ""
    }
} 

export const defaultQuanitityUnit = (unitArray: IUnitDropdown[], valueName: string) => {
    let unitId = ""
    let unitLabel = ""

    const matchedObj = unitArray?.find((each) => each.attributes.unit_name.toLowerCase() == valueName.toLowerCase())

    if((unitArray.length === 1 && matchedObj) || (unitArray.length >1 && matchedObj)){
        unitId = matchedObj.id
        unitLabel = matchedObj.attributes.unit_name        
    }else if(unitArray.length === 1 && !matchedObj){
        unitId = unitArray[0]?.id || ""
        unitLabel = unitArray[0]?.attributes.unit_name || ""
    }
    return { unitId, unitLabel }
}

export const getLabelFromId = (valueId: string | number, areaUnitList: IUnitDropdown[]) => {
    let label = ""
    const resultObject = areaUnitList.find((each) => each.id?.toString() === valueId?.toString())

    if (resultObject) {
      label = resultObject.attributes.name
    }
    return label
  }

  export const dateDDMMYYYY = (date: Date | string | null) => {
    let newDate = ""
    if (date) {
        newDate = moment(date).format('DD/MM/YYYY')
    }
    return newDate
}
export const returnWeedEndpoint = (userAccountId: string, currentSelectedYear: number, selectedFarmIds: string[], selectedCropsId: string[]) => {
    let endpoint = `/mobile_and_web/farm_dairy/activity_weed_managements?user_id=${userAccountId}&year=${currentSelectedYear}`

    if (selectedFarmIds.length || selectedCropsId.length) {
        endpoint = endpoint + `&land_id=${selectedFarmIds}&crop_id=${selectedCropsId}`
    }
    return endpoint;
}
export const returnPestEndpoint = (userAccountId: string, currentSelectedYear: number, selectedFarmIds: string[], selectedCropsId: string[]) => {
    let endpoint = `mobile_and_web/farm_dairy/activity_pest_managements?user_id=${userAccountId}&year=${currentSelectedYear}`
    if (selectedFarmIds.length || selectedCropsId.length) {
        endpoint = endpoint + `&land_detail_id=${selectedFarmIds}&crop_id=${selectedCropsId}`
    }
    return endpoint;
}
export const returnSellEndpoint = (userAccountId: string, currentSelectedYear: number, selectedFarmIds: string[], selectedCropsId: string[]) => {
    let endpoint = `mobile_and_web/farm_dairy/activity_sells?user_id=${userAccountId}&year=${currentSelectedYear}`

    if (selectedFarmIds.length || selectedCropsId.length) {
        endpoint = endpoint + `&land_id=${selectedFarmIds}&crop_id=${selectedCropsId}`
    }
    return endpoint;
}

export const getSowingDate = (value: number[], cropNamesList:ISelectCrop[] ) => {
    let sowingDates = value.map(id => {
        const crop = cropNamesList.find(item => item.id == id.toString());
        return crop?.attributes?.sowing_date;
    }).filter(date => date);

    const sowingDatesUTC = sowingDates.map(sowingDate => {
        const dateObject = new Date(sowingDate as string); 
        return dateObject.setHours(0, 0, 0, 0);   
    });
    const dateObjects = sowingDatesUTC.map(sowingDateUTC => new Date(sowingDateUTC)); 
    const latestDate = new Date(Math.max(...dateObjects.map(dateItem => +new Date(dateItem))))
    return moment(latestDate).add(1, 'days').toDate();
}

export const getSingleCropSowingDate = (value: string, cropNameList: Array<{id: string | number, attributes: {sowing_date: string}}> ) => {
    const sowingDate = moment((cropNameList?.find((item: { id: string | number }) => item.id == value)?.attributes?.sowing_date)).toDate();
    return  moment(sowingDate).add(1, 'days').toDate();
};

export const toLowerSnakeCase = (input: string) => {
    return input
        .replace(/[^\w\s-]/g, '')
        .trim()
        .replace(/[\s-]+/g, '_')
        .toLowerCase();
}
export const languageError = (language: string, isErrorEnglish: boolean, isErrorGujarati: boolean, isErrorHindi: boolean) => {
    switch (language) {
        case 'english':
            return { isErrorEnglish: true, isErrorGujarati, isErrorHindi };
        case 'gujarati':
            return { isErrorEnglish, isErrorGujarati: true, isErrorHindi };
        case 'hindi':
            return { isErrorEnglish, isErrorGujarati, isErrorHindi: true };
        default:
            return { isErrorEnglish, isErrorGujarati, isErrorHindi };
    }
};
export const isLanguageKey = (language: string) => {
    return language !== 'id' && language !== "_destroy"
}

export const trimStart = (str: string) => {
    return str?.trimLeft()
}

export const formateDataSimple = (data: IDropdownDetailsData[]) => {
    let formattedData = []
    formattedData = data[0]?.data?.length ? data[0]?.data?.map((obj) => {
        return {
            "id": obj.id,
            "english": {
                value: obj?.name || obj?.label,
                error: ""
            },
            "gujarati": {
                value: obj?.name_gujrati || obj?.label_gujrati,
                error: ""
            },
            "hindi": {
                value: obj?.name_hindi || obj?.label_hindi,
                error: ""
            }
        }
    }) : []
    return formattedData
}

export const formateUnitData = (data: IDropdownDetailsData[]) => {
    let formattedData = []
    formattedData = data[0]?.data?.length ? data[0]?.data?.map((obj) => {
        return {
            "id": returnTruthyString(obj?.id),
            "name": returnTruthyString(obj?.name_english),
            "checked": Boolean(obj?.checked)
        }
    }) : []
    return formattedData
}

export const formateUnitBodyData = (formData: IUnitMenu[]) => {
    let bodyData = []
    bodyData = formData.map((form) => {
        return {
            "id": String(form.id),
            "checked": form.checked
        }
    })
    return bodyData
}
export const getTitleMessage = (responseJson: IResponseDropdowns[]) => {

    const result = responseJson.find(item => item.hasOwnProperty('errors'));
    let title = ""
    let message = ""
    if (result && result.errors) {
        const words: string[] = result.errors.split(' ');
        // Remove the first word if it's a number
        if (!isNaN(parseInt(words[0]))) {
            words.shift();
        }
        title = words.includes("true") ? "Success" : "Warning !"
        message = words.includes("true") ? "Dropdown Saved Successfully." : words.join(' ')
    }
    return { title, message }
}

export const formateSimpleBodyType1 = (formData: ISimpleDropdownFormData[]) => {
    let bodyData = []
    bodyData = formData.map((form) => {
        if (form?._destroy) {
            return { _destroy: form.id };
        }

        return {
            ...(form.id &&
            {
                "id": String(form.id)
            }),
            name: form.english.value.trim(),
            name_english: form.english.value.trim(),
            name_gujrati: form.gujarati.value.trim(),
            name_hindi: form.hindi.value.trim(),
            active: true
        }
    })
    return bodyData
}

export const formateSimpleBodyType2 = (formData: ISimpleDropdownFormData[]) => {
    let bodyData = []
    bodyData = formData.map((form) => {
        if (form?._destroy) {
            return { _destroy: form.id };
        }

        return {
            ...(form.id &&
            {
                "id": String(form.id)
            }),
            label: form.english.value.trim(),
            label_english: form.english.value.trim(),
            label_gujrati: form.gujarati.value.trim(),
            label_hindi: form.hindi.value.trim(),
            active: true
        }
    })
    return bodyData
}
export const DropdownKey = () => {
    let dropdownName = ""
    let subDropdownName = ""
    const searchParams = new URLSearchParams(window.location.search);
    const dropdownValue = searchParams.get('dropdown');
    const subDropdownValue = searchParams.get('sub-dropdown');
    if (dropdownValue && subDropdownValue) {
        dropdownName = dropdownValue
        subDropdownName = subDropdownValue

    }
    return { dropdownName, subDropdownName }
}
export const IrrigationReqKey = (title: string, sourceIrrigation: string, typeIrrigation: string) => {
    let keyName = ""
    if (title == sourceIrrigation) {
        keyName = "source_irrigation"
    } else if (title == typeIrrigation) {
        keyName = "type_irrigation"
    } else {
        keyName = toLowerSnakeCase(title)
    }

    return keyName
}

export const formateCropNameData = (data: IDropdownDetailsData[]) => {
    let formattedData = []
    formattedData = data[0]?.data?.length ? data[0]?.data?.map((obj) => {
        return {
            "id": returnTruthyString(obj.id),
            image: returnTruthyString(obj.crop_photo?.url) || returnTruthyString(obj.crop_photo?.thumb?.url),
            crop_days: Number(returnTruthyString(obj?.crop_days)),
            harvest_type: returnTruthyString(obj?.harvest_type),
            crop_season: returnTruthyString(obj?.crop_season_id?.id),
            "english": {
                crop_name: obj?.name_english,
                crop_name_error: ""
            } as ICropLanguageObject,
            "gujarati": {
                crop_name: obj?.name_gujrati,
                crop_name_error: ""
            } as ICropLanguageObject,
            "hindi": {
                crop_name: obj?.name_hindi,
                crop_name_error: ""
            } as ICropLanguageObject,
            error: {
                image: "",
                crop_days: "",
                harvest_type: "",
                crop_season: "",
            } as ICropErrorObject
        }
    }) : []
    return formattedData
}

export const formateCropNameBody = (formData: IDefaultCropFormObj[]) => {
    let bodyData = []
    bodyData = formData.map((form) => {
        if (form?._destroy) {
            return { _destroy: form.id };
        }

        return {
            ...(form.id &&
            {
                "id": String(form.id)
            }),
            name: (form.english as ICropLanguageObject).crop_name.trim(),
            name_english: (form.english as ICropLanguageObject).crop_name.trim(),
            name_gujrati: (form.gujarati as ICropLanguageObject).crop_name.trim(),
            name_hindi: (form.hindi as ICropLanguageObject).crop_name.trim(),
            crop_photo: form.image,
            harvest_type: form.harvest_type,
            crop_days: Number(form.crop_days),
            crop_season_id: Number(form.crop_season),
            active: true
        }
    })
    return bodyData
}


export const generateRandomString = (originalString: string) => {

    const randomLength = Math.floor(Math.random() * (originalString.length + 1));
    let randomString = '';
    for (let i = 0; i < randomLength; i++) {
        const randomIndex = Math.floor(Math.random() * originalString.length);
        randomString += originalString.charAt(randomIndex);
    }
    return randomString;
}

export const returnImgUrl = (url: string) => {
    let imgURL = ""
    const randomString = generateRandomString(String(Date.now()))
    imgURL = url.startsWith("https://") || url.startsWith("http://") ? url + `?_=${randomString}` : url
    return imgURL
}

export const validateAllSimple = (allForms: IDefaultValueSimple[]) => {
        let isFormValidated = true
        let isErrorEnglish = false;
        let isErrorGujarati = false;
        let isErrorHindi = false;

    allForms.forEach((newForm) => {

        if (newForm?._destroy) {
            (newForm['english'] as ILanguageObjectSimple).error = "";
            (newForm['gujarati'] as ILanguageObjectSimple).error = "";
            (newForm['hindi'] as ILanguageObjectSimple).error = "";
        } else {
            for (const language in newForm) {
                if (isLanguageKey(language)) {
                    const currentLanguage = newForm[language] as ILanguageObjectSimple;
                    if (currentLanguage.value.trim() == '') {
                        currentLanguage.error = "Field cannot be empty";
                        isFormValidated = false;
                        ({ isErrorEnglish, isErrorGujarati, isErrorHindi } = languageError(language, isErrorEnglish, isErrorGujarati, isErrorHindi));
                    } else {
                        currentLanguage.error = "";
                    }
                }
            }
        }
    })

    return { isErrorEnglish,isErrorGujarati,isErrorHindi,isFormValidated,allForms}
}

export const validateAllUnit = (allForms: IDefaultValueUnit[]) => {
    let isErrorEnglish = false;
    let isErrorGujarati = false;
    let isErrorHindi = false;

    allForms.forEach((newForm) => {

        if (newForm?._destroy) {
            for (const key in newForm) {
                if (key !== "id") {
                    const currentLanguage = newForm[key] as IValueUnit;
                    for (const field in currentLanguage) {
                        currentLanguage[field].error = "";
                    }
                }
            }
        } else {
            for (const language in newForm) {
                if (isLanguageKey(language)) {
                    const currentLanguage = newForm[language] as IValueUnit;

                    for (const field in currentLanguage) {
                        if (currentLanguage[field].value.toString().trim() === '') {
                            currentLanguage[field].error = "Field cannot be empty";
                            ({ isErrorEnglish, isErrorGujarati, isErrorHindi } = languageError(language, isErrorEnglish, isErrorGujarati, isErrorHindi));
                        } else {
                            currentLanguage[field].error = "";
                        }
                    }
                }
            }
        }

    })
    return { isErrorEnglish,isErrorGujarati,isErrorHindi,allForms}
}
// CROP FORM
export const returnError = (value: string) => {
    const error = value.trim() !== '' ? "" : "Field cannot be empty"
    return error
}
const isOtherKeys = (key: string) => {
    const otherKeysArray = ["image", "crop_days", "harvest_type", "crop_season", "error"]
    return otherKeysArray.includes(key)
}
export const isLanguageKeys = (key: string) => {
    const languageKeysArray = ["english", "gujarati", "hindi"]
    return languageKeysArray.includes(key)
}
const isErrorKey = (key: string) => {
    const errorKeyArray = ["error"]
    return errorKeyArray.includes(key)
}
const otherErrors = (newForm: IDefaultCropFormObj) => {
    const image_error = returnError(String(newForm.image));
    const crop_days_error = returnError(String(newForm.crop_days));
    const harvest_type_error = returnError(String(newForm.harvest_type));
    const crop_season_error = returnError(String(newForm.crop_season));

    return { image_error, crop_days_error, harvest_type_error, crop_season_error }
}
export const validateCropNameForm = (newForm: IDefaultCropFormObj) => {

    let isErrorEnglish = false
    let isErrorGujarati = false
    let isErrorHindi = false

    let isErrorInOtherField = false

    const formObj = { ...newForm }

    if (formObj?._destroy) {
        for (const key in formObj) {
            const currentObject = formObj[key] as ICropLanguageObject;
            if (typeof currentObject === 'object' && currentObject !== null && !Array.isArray(currentObject)) {
                currentObject.crop_name_error = ""
            }
        }
        formObj.error.image = "";
        formObj.error.crop_days = "";
        formObj.error.harvest_type = "";
        formObj.error.crop_season = "";

    } else {
        for (const key in formObj) {
            if (isLanguageKeys(key)) {
                const currentObject = formObj[key] as ICropLanguageObject;
                if (typeof currentObject === 'object' && currentObject !== null && !Array.isArray(currentObject)) {
                    currentObject.crop_name_error = returnError(currentObject.crop_name)
                }
            } else if (isOtherKeys(key)) {
                const { image_error, crop_days_error, harvest_type_error, crop_season_error } = otherErrors(formObj)
                if (isErrorKey(key)) {
                    (formObj[key] as ICropErrorObject).image = image_error;
                    (formObj[key] as ICropErrorObject).crop_days = crop_days_error;
                    (formObj[key] as ICropErrorObject).harvest_type = harvest_type_error;
                    (formObj[key] as ICropErrorObject).crop_season = crop_season_error;
                }
            }
        }
    }

    isErrorEnglish = Boolean((formObj['english'] as ICropLanguageObject).crop_name_error)
    isErrorGujarati = Boolean((formObj['gujarati'] as ICropLanguageObject).crop_name_error)
    isErrorHindi = Boolean((formObj['hindi'] as ICropLanguageObject).crop_name_error)
    isErrorInOtherField = Object.values(formObj?.error).some(value => value);

    const isFormHasError = isErrorEnglish || isErrorGujarati || isErrorHindi || isErrorInOtherField

    return { isFormHasError, isErrorEnglish, isErrorGujarati, isErrorHindi, isErrorInOtherField, formObj }
}
// MATERRAPEDIA

export const InputAndLabelLibrary = {
    "input_label": "Chapter Name",
    "input_placeholder": "Enter chapter name",
    "image_label": "Chapter Image",
    "image_placeholder": "Upload chapter image here..."
}

export const InputAndLabelFAQ = {
    "input_label": "Category Name",
    "input_placeholder": "Enter category image",
    "image_label": "Category Image",
    "image_placeholder": "Upload category image here..."
}

export const formateLibraryFAQData = (data: IDropdownDetailsData[]) => {
    let formattedData = []
    formattedData = data[0]?.data?.length ? data[0]?.data?.map((obj) => {
        return {
            "id": returnTruthyString(obj.id),
            image: returnTruthyString(obj.image?.resized?.url || obj.image?.url),
            image_error: "",
            "english": {
                title: returnTruthyString(obj?.name),
                title_error: ""
            },
            "gujarati": {
                title: returnTruthyString(obj?.name_gujarati),
                title_error: ""
            },
            "hindi": {
                title: returnTruthyString(obj?.name_hindi),
                title_error: ""
            }
        }
    }) : []
    return formattedData
}

export const validateLibraryFAQForm = (newForm: IDefaultLibraryFAQ) => {

    let isErrorEnglish = false
    let isErrorGujarati = false
    let isErrorHindi = false

    let isErrorInOtherField = false

    const formObj = { ...newForm }

    if (formObj?._destroy) {
        for (const key in formObj) {
            const currentObject = formObj[key] as ISubValueLibraryFAQ;
            if (typeof currentObject === 'object' && currentObject !== null && !Array.isArray(currentObject)) {
                currentObject.title_error = ""
            }
        }
        formObj.image_error = "";

    } else {
        for (const key in formObj) {
            if (isLanguageKeys(key)) {
                const currentObject = formObj[key] as ISubValueLibraryFAQ;
                if (typeof currentObject === 'object' && currentObject !== null && !Array.isArray(currentObject)) {
                    currentObject.title_error = returnError(currentObject.title)
                }
            } else if (isOtherKeys(key)) {
                formObj.image_error = returnError(String(formObj.image))
            }
        }
    }

    isErrorEnglish = Boolean((formObj['english'] as ISubValueLibraryFAQ).title_error)
    isErrorGujarati = Boolean((formObj['gujarati'] as ISubValueLibraryFAQ).title_error)
    isErrorHindi = Boolean((formObj['hindi'] as ISubValueLibraryFAQ).title_error)
    isErrorInOtherField = Boolean(formObj?.image_error)

    const isFormHasError = isErrorEnglish || isErrorGujarati || isErrorHindi || isErrorInOtherField

    return { isFormHasError, isErrorEnglish, isErrorGujarati, isErrorHindi, isErrorInOtherField, formObj }
}

export const formateLibraryFAQBody = (formData: IDefaultLibraryFAQ[]) => {
    let bodyData = []
    bodyData = formData.map((form) => {
        if (form?._destroy) {
            return { _destroy: String(form.id) };
        }

        return {
            ...(form.id &&
            {
                "id": String(form.id)
            }),
            name: (form['english'] as ISubValueLibraryFAQ).title.trim(),
            name_gujarati: (form['gujarati'] as ISubValueLibraryFAQ).title.trim(),
            name_hindi: (form['hindi'] as ISubValueLibraryFAQ).title.trim(),
            image: form?.image
        }
    })
    return bodyData
}


export const getDynamicYears = () => {
    const currentYear = new Date().getFullYear();
    const years = [];

    for (let i = currentYear - 3; i <= currentYear + 1; i++) {
        let key;
        if (i === currentYear - 3) {
            key = `${i}-${(i + 1).toString().slice(-2)}`;
        } else {
            key = `${i}-${(i + 1).toString().slice(-2)}`;
        }
        years.push({ key, value: i });
    }
    return years;
}

export const isValidURL = (URL: string | null | undefined) => {
    return returnTruthyString(URL).startsWith("https://") || returnTruthyString(URL).startsWith("http://")
}

export const handleDownload = async (url:string) => {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
  
    const blob = await response.blob();
    const filename = url.split('/').pop(); 
  
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    if (typeof filename === 'string') {  
      link.download = filename;
    }
    link.click();
  
    URL.revokeObjectURL(link.href);
};

export const extractFileNameFromUrl = (url: string) => {
    const fileName =  url.split('/').pop(); 
    return fileName;
}
  
export const BooleanKey = (keyName: string) => {
    if (keyName == "true") return true
    if (keyName == "false") return false
}
export const returnFarmerEndpoint =
    (baseUrl: string, searchValue: string,
        stateIdKey: string, districtIdKey: string,
        talukaIdKey: string, villageIdKey: string,
        FEIdKey: string, pageNumber: number,
        sortAscendingFL: boolean, sortDescendingFL: boolean,
        selectedStatus: { name: string, value: string }
    ) => {
        const stateId = stateIdKey || ""
        const districtId = districtIdKey || ""
        const talukaId = talukaIdKey || ""
        const villageId = villageIdKey || ""
        const fieldExecutiveId = FEIdKey || ""
        let page: number = pageNumber

        let endpoint = `${baseUrl}?search=${searchValue}&state_id=${stateId}&district_id=${districtId}&taluka_id=${talukaId}&village_id=${villageId}&per_page=${20}&page=${page}&field_executive_id=${fieldExecutiveId}`;
        if (sortAscendingFL === true) {
            endpoint = endpoint + `&sort_by_a_to_z=${true}`
        }
        else if (sortDescendingFL) {
            endpoint = endpoint + `&sort_by_z_to_a=${true}`
        }
        if (selectedStatus.value) {
            endpoint = endpoint + `&status=${BooleanKey(selectedStatus.value)}`
        }
        return endpoint
    }


export const getURL = (url1: string | null | undefined, url2?: string | null , url3?: string| null ) => {
    return returnTruthyString(url1 || url2 || url3)
}
export const notNullValue = (value: string | null | number) => {
    if (value == null) {
        return ""
    }
    return value
} 

export const importValidationSchema = (bulkUpload:string ) => {
    return Yup.object().shape({
      document: Yup.mixed()
      .required("Please select a file to upload.")
      .test("fileSize", "File is too large. Maximum allowed size is 250 MB.",(file) => file?.size <= 250 * 1024 * 1024)
      .test("fileType", "Invalid file type.",(file) => bulkUpload === "update" ? (file && /.(xlsx)$/.test(file.name)) : (file && /.(csv)$/.test(file.name))),
    })
}

export const checkCondition = (condition: boolean, trueValue: unknown, falseValue: unknown) => {
    return condition ? trueValue : falseValue
}

export const invalidDate = (value: string | null) => {
    if (value !== null && (value == "Invalid Date")) {
        return "Please enter valid Date"
    } else if (value !== null && value !== "Invalid Date") {
        const minDate = new Date();
        minDate.setFullYear(minDate.getFullYear() - 3)
        minDate.setDate(minDate.getDate() - 1);
        if (new Date(value) < minDate) {
            return "Please select valid minimum Date"
        }
    }
    return ""
}

export const invalidTime = (value: string | null | undefined) => {
    if (value !== null && (value == "Invalid Date")) {
        return "Please select valid Time"
    }
    return ""
}

export const setimageBody = (formValues: FormDataType) => {
    if (formValues.image?.file) {
      return formValues.image?.file
    } else if (formValues.image?.url) {
      return formValues.image?.url
    }
  }

// MANDI PRICE FUNCTIONS  
export const formateTalukaForms = (data: IDropdownDetails[]) => {
    let formattedData: any = []
    formattedData = data[0]?.data?.map((obj) => {
        return {
            "id": obj.id,
            "english": {
                value: obj.name_english,
                error: ""
            },
            "gujarati": {
                value: obj.name_gujrati,
                error: ""
            },
            "hindi": {
                value: obj.name_hindi,
                error: ""
            },
            districtId: returnTruthyString(obj.district_id),
            mandiName: returnTruthyString(obj.mandi),
            stateError: "",
            districtError: "",
            mandiError: "",
        }
    })

    return formattedData
}

export const formateTalukaBodyData = (formData: IDefaultFormTaluka[]) => {
    let bodyData = []
    bodyData = formData.map((form: any) => {
        if (form?._destroy) {
            return { _destroy: form.id };
        }

        return {
            ...(form.id &&
            {
                "id": form.id
            }),
            name: String(form.english.value).trim(),
            name_english: String(form.english.value).trim(),
            name_gujrati: String(form.gujarati.value).trim(),
            name_hindi: String(form.hindi.value).trim(),
            active: true,
            district_id: form.districtId,
            mandi: form.mandiName
        }
    })

    return bodyData
}

export const validateTalukaForm = (newForm: IDefaultFormTaluka, validateList: boolean = false) => {

    let isErrorEnglish = false
    let isErrorGujarati = false
    let isErrorHindi = false

    let isErrorInOtherField = false

    const formObj = { ...newForm }

    if (formObj?._destroy) {
        for (const key in formObj) {
            const currentObject = formObj[key] as ILanguageObjectTaluka;
            if (typeof currentObject === 'object' && currentObject !== null && !Array.isArray(currentObject)) {
                currentObject.error = ""
            }
        }
        formObj.stateError = "";
        formObj.districtError = "";
        formObj.mandiError = "";

    } else {
        for (const key in formObj) {
            if (isLanguageKeys(key)) {
                const currentObject = formObj[key] as ILanguageObjectTaluka;
                if (typeof currentObject === 'object' && currentObject !== null && !Array.isArray(currentObject)) {
                    currentObject.error = returnError(currentObject.value)
                }
            }
            if (validateList) {
                formObj.stateError = ""
                formObj.districtError = ""
                formObj.mandiError = checkCondition(Boolean(formObj.mandiName), "", "Please select Mandi") as string
            } else {
                formObj.stateError = checkCondition(Boolean(formObj.stateId), "", "Please select State") as string
                formObj.districtError = checkCondition(Boolean(formObj.districtId), "", "Please select District") as string
                formObj.mandiError = checkCondition(Boolean(formObj.mandiName), "", "Please select Mandi") as string
            }
        }
    }

    isErrorEnglish = Boolean((formObj['english'] as ILanguageObjectTaluka).error)
    isErrorGujarati = Boolean((formObj['gujarati'] as ILanguageObjectTaluka).error)
    isErrorHindi = Boolean((formObj['hindi'] as ILanguageObjectTaluka).error)
    isErrorInOtherField = Boolean(formObj.stateError || formObj.districtError || formObj.mandiError)

    const isFormHasError = isErrorEnglish || isErrorGujarati || isErrorHindi || isErrorInOtherField
    return { isFormHasError, isErrorEnglish, isErrorGujarati, isErrorHindi, isErrorInOtherField, formObj }
}

export const getNameChar = (name: string | null | undefined) => {
    let finalStirng = ""
    const trimName = name?.trim()
    if (trimName) {
        const nameArr = trimName.split(' ')
        finalStirng = (nameArr[0][0] + `${nameArr[1] ? nameArr[1][0] : ''}`).toUpperCase();
    }
    return finalStirng
} 

export const checkANDcondition = (key1: boolean, key2: boolean) => {
    return key1 && key2
}

export const getErrorAdminProfile = (error:string | {token:string}) =>{
    if (typeof error === 'string') return error
    if (typeof error.token === 'string') return error.token
    return ""
}